import type { NuxtCookies, GetOptions } from 'cookie-universal-nuxt';
import type { CookieSerializeOptions } from 'cookie';
import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/magento/helpers';
import { defaultConfig } from '~/modules/magento/defaultConfig';

const moduleOptions = JSON.parse('{"cookies":{"currencyCookieName":"vsf-currency","countryCookieName":"vsf-country","localeCookieName":"vsf-locale","cartCookieName":"vsf-cart","customerCookieName":"vsf-customer","storeCookieName":"vsf-store","messageCookieName":"vsf-message","memberCookieName":"vsf-member","selectedCompanyCookieName":"vsf-selected-company","isAcceptedTermsCookieName":"vsf-is-accepted-terms","canApproveMemberCookieName":"vsf-can-approve-member","isCountrySelectedCookieName":"vsf-is-country-selected","isRememberMeCookieName":"vsf-is-remember-me","permissionsCookieName":"vsf-permissions"},"externalCheckout":{"enable":false,"cmsUrl":"https://staging.vizulo.com","syncUrlPath":"/vue/cart/sync","stores":{"us":false}},"defaultStore":"en","facets":{"available":["color","size","price"]},"magentoBaseUrl":"https://staging.vizulo.com/","imageProvider":"ipx","magentoApiEndpoint":"https://staging.vizulo.com/graphql","customApolloHttpLinkOptions":{"useGETForQueries":false}}');

export default integrationPlugin((plugin) => {
	const getCookieName = (property: string) : string => moduleOptions.cookies?.[property] ?? defaultConfig.cookies[property];

	const cookieNames = {
		cart: getCookieName('cartCookieName'),
		customer: getCookieName('customerCookieName'),
		currency: getCookieName('currencyCookieName'),
		store: getCookieName('storeCookieName'),
		locale: getCookieName('localeCookieName'),
		country: getCookieName('countryCookieName'),
		message: getCookieName('messageCookieName'),
		member: getCookieName('memberCookieName'),
        selectedCompany: getCookieName('selectedCompanyCookieName'),
		isAcceptedTerms: getCookieName('isAcceptedTermsCookieName'),
		canApproveMember: getCookieName('canApproveMemberCookieName'),
		isCountrySelected: getCookieName('isCountrySelectedCookieName'),
		isRememberMe: getCookieName('isRememberMeCookieName'),
		permissions: getCookieName('permissionsCookieName'),
	};

	const { $cookies } = plugin.app;

	const createCookieOperationsInstance = <TValue = string>(cookies: NuxtCookies) => (cookieName: string) => ({
		get: (opts?: GetOptions) => cookies.get(cookieName, opts),
		set: (value: TValue, opts?: CookieSerializeOptions) => cookies.set(cookieName, value, opts),
		remove: (opts?: CookieSerializeOptions) => cookies.remove(cookieName, opts),
	});

	const createCookieOperations = createCookieOperationsInstance($cookies);

	// TODO Refactor to separate containers (state.cart.get() .set() .remove()) - this requires a breaking change in api-client types

	const {
		get: getCartId,
		set: setCartId,
		remove: removeCartId,
	} = createCookieOperations(cookieNames.cart);

	const {
		get: getCustomerToken,
		set: setCustomerToken,
		remove: removeCustomerToken,
	} = createCookieOperations(cookieNames.customer);

	const {
		get: getStore,
		set: setStore,
		remove: removeStore,
	} = createCookieOperations(cookieNames.store);

	const {
		get: getCurrency,
		set: setCurrency,
		remove: removeCurrency,
	} = createCookieOperations(cookieNames.currency);

	const {
		get: getLocale,
		set: setLocale,
		remove: removeLocale,
	} = createCookieOperations(cookieNames.locale);

	const {
		get: getCountry,
		set: setCountry,
		remove: removeCountry,
	} = createCookieOperations(cookieNames.country);

	const {
		get: getMessage,
		set: setMessage,
		remove: removeMessage,
	} = createCookieOperations(cookieNames.message);

	const {
		get: getIsMember,
		set: setIsMember,
		remove: removeIsMember,
	} = createCookieOperations(cookieNames.member);

	const {
		get: getIsAcceptedTerms,
		set: setIsAcceptedTerms,
		remove: removeIsAcceptedTerms,
	} = createCookieOperations(cookieNames.isAcceptedTerms);

	const {
		get: getCanApproveMember,
		set: setCanApproveMember,
		remove: removeCanApproveMember,
	} = createCookieOperations(cookieNames.canApproveMember);

	const {
		get: getIsCountrySelected,
		set: setIsCountrySelected,
		remove: removeIsCountrySelected
	} = createCookieOperations(cookieNames.isCountrySelected);

	const {
		get: getIsRememberMe,
		set: setIsRememberMe,
		remove: removeIsRememberMe
	} = createCookieOperations(cookieNames.isRememberMe);

	const {
		get: getPermissions,
		set: setPermissions,
		remove: removePermissions,
	} = createCookieOperations(cookieNames.permissions);

    const {
        get: getSelectedCompany,
        set: setSelectedCompany,
        remove: removeSelectedCompany,
    } = createCookieOperations(cookieNames.selectedCompany);

	const settings = mapConfigToSetupObject({
		moduleOptions,
		app: plugin.app,
		additionalProperties: {
			state: {
				getCartId,
				setCartId,
				removeCartId,

				getCustomerToken,
				setCustomerToken,
				removeCustomerToken,

				getStore,
				setStore,
				removeStore,

				getCurrency,
				setCurrency,
				removeCurrency,

				getLocale,
				setLocale,
				removeLocale,

				getCountry,
				setCountry,
				removeCountry,

				getMessage,
				setMessage,
				removeMessage,

				getIsMember,
				setIsMember,
				removeIsMember,

				getIsAcceptedTerms,
				setIsAcceptedTerms,
				removeIsAcceptedTerms,

				getCanApproveMember,
				setCanApproveMember,
				removeCanApproveMember,

				getIsCountrySelected,
				setIsCountrySelected,
				removeIsCountrySelected,

				getIsRememberMe,
				setIsRememberMe,
				removeIsRememberMe,

				getPermissions,
				setPermissions,
				removePermissions,

                getSelectedCompany,
                setSelectedCompany,
                removeSelectedCompany
			},
		},
	});

	plugin.integration.configure('magento', settings);
});
